import { Link, graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import * as s from '~/components/pages/blog/blog.module.scss'
import Arrow from '~/images/common_icon_arrow_r.svg'
import { Seo, Pagination, TagList } from '~/components'

export default function BlogCategoryIndex({ data, pageContext }) {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return <p>No Found</p>
  }

  return (
    <>
      <Seo
        title={pageContext.categoryName}
        pageUrl={`/category/${pageContext.pageContext}`}
      />

      <div className="blog">
        <section className="contentComponent">
          <header className="titleComponent">
            <h1 className="heading pageTitle">{pageContext.categoryName}</h1>
          </header>
          <ul className="breadcrumbComponent">
            <li>
              <Link to="/" itemProp="url">
                TOP
              </Link>
              <span>
                <Arrow />
              </span>
            </li>
            <li>
              <Link to="/blog" itemProp="url">
                CATEGORY
              </Link>
              <span>
                <Arrow />
              </span>
            </li>
            <li>{pageContext.categoryName}</li>
          </ul>
          <div className={s.articleList}>
            {posts.map(post => {
              const title = post.title
              const featuredImage = {
                image:
                  post.featuredImage?.node?.localFile?.childImageSharp
                    ?.gatsbyImageData,
                alt: post.featuredImage?.node?.alt || ``,
              }
              const firstCategory = {
                name: post.categories?.nodes[0]?.name,
                uri: post.categories?.nodes[0]?.uri,
              }
              const tags = post.tags?.nodes

              return (
                <article
                  className={s.articleItem}
                  itemScope
                  itemType="http://schema.org/Article"
                  key={post.id}
                >
                  <Link to={`/blog${post.uri}`} itemProp="url">
                    <div className={s.thumbnail}>
                      {featuredImage?.image ? (
                        <GatsbyImage
                          image={featuredImage.image}
                          alt={featuredImage.alt}
                        />
                      ) : (
                        <StaticImage
                          src="../images/top_mv_pc.jpg"
                          alt=""
                          placeholder="blurred"
                          layout="fullWidth"
                        />
                      )}
                    </div>
                    <section className={s.articleDetail}>
                      <header>
                        {firstCategory?.name && (
                          <div className={s.articleCategory}>
                            <span>{firstCategory.name}</span>
                          </div>
                        )}
                        <h2
                          className={s.articleTitle + ' heading is-secondary'}
                        >
                          <span itemProp="headline">{parse(title)}</span>
                        </h2>
                      </header>
                      <small className={s.articleDate + ' heading'}>
                        {post.date} UP
                      </small>
                      {tags.length > 0 && <TagList type={'tag'} tags={tags} />}
                    </section>
                  </Link>
                </article>
              )
            })}
          </div>
        </section>

        <Pagination pageContext={pageContext} />
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query WordPressPostCategoryArchive(
    $id: String!
    $offset: Int!
    $postsPerPage: Int!
  ) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        id
        date(formatString: "YYYY.MM.DD")
        title
        excerpt
        tags {
          nodes {
            uri
            name
            slug
          }
        }
        categories {
          nodes {
            name
            uri
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`
